<template>
  <div>
    <el-popover
      style="margin: 0 10px"
      placement="bottom"
      width="160"
      trigger="click">
      <div class="right-panel">
        <ul class="operate-list">
          <li class="operate-item">
            <el-upload
              class="upload-demo upload_button"
              action="http://localhost:8443/content/uploadFile"
              :on-change="handleFileChange"
              :auto-upload="false"
              :file-list="fileList"
              :show-file-list="false"
              multiple
              ref="upload">
              <div slot="trigger" style="display: flex;">
                <span class="icon">
                  <svg-icon icon-class="uploadFile"></svg-icon>
                </span>
                <span class="operate">上传文件</span>
              </div>
            </el-upload>
          </li>
          <!-- <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="uploadFolder"></svg-icon>
            </span>
            <span class="operate">上传文件夹</span>
          </li> -->
        </ul>
      </div>
      <el-button slot="reference" type="success" size="small" plain>上传<i class="el-icon-arrow-down el-icon--right"></i></el-button>
    </el-popover>
    <el-popover
      v-if="type !== 'sharedme'"
      placement="bottom"
      width="160"
      trigger="hover">
      <div class="right-panel">
        <ul class="operate-list">
          <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="file"></svg-icon>
            </span>
            <span class="operate" @click="addArticleClick">新建文档</span>
          </li>
          <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="folder_on"></svg-icon>
            </span>
            <span class="operate" @click="addFolderClick">新建文件夹</span>
          </li>
          <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="excel_icon"></svg-icon>
            </span>
            <span class="operate" @click="addNewFileClick('newExcel')">新建excle</span>
          </li>
          <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="word"></svg-icon>
            </span>
            <span class="operate" @click="addNewFileClick('newDoc')">新建word</span>
          </li>
          <li class="operate-item">
            <span class="icon">
              <svg-icon icon-class="ppt"></svg-icon>
            </span>
            <span class="operate" @click="addNewFileClick('newPowerPoint')">新建PowerPoint</span>
          </li>
        </ul>
      </div>
      <el-button slot="reference" type="primary" size="small">新建<i class="el-icon-arrow-down el-icon--right"></i></el-button>
    </el-popover>
    <!-- 添加文件夹 弹窗 -->
    <folder-add
      :visible="visible"
      :encryptionStr="encryptionStr"
      :file_type="file_type"
      @closeTab="closeTab"
      @addFolderSuccess="addFolderSuccess">
    </folder-add>
    <!-- 上传文件 弹窗 -->
    <!-- <upload-file-dialog
      :visible="uploadVisible"
      :encryptionStr="encryptionStr"
      @closeDialog="closeDialog"
      @uploadSuccess="uploadSuccess">
    </upload-file-dialog> -->
  </div>
</template>
<script>
import { getForId, addContentSpaceNew } from '@/api/space'
import folderAdd from '@/components/addFolderDialog/index'
import {validateFile} from '@/utils/index'
// import uploadFileDialog from '@/components/uploadFileDialog/index'
export default {
  components: {
    folderAdd
    // uploadFileDialog
  },
  data() {
    return {
      isLoading: false,
      visible: false,
      file_type: '',
      uploadVisible: false,
      fileList: []
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    encryptionStr: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleFileChange(file) {
      if (!validateFile(file)) {
        return
      }
      file.encryptionStr = this.encryptionStr
      file.uplaodTime = new Date()
      // this.$store.dispatch('updateUploadList', file)
      const oldFileList = this.$store.getters.uploadFileList
      oldFileList.push(Object.assign({}, file, {
        upLoadProgress: 0,
        isError: false
      }))
      this.$store.dispatch('updateUploadList', oldFileList)
    },
    // 上传文件
    // uploadFileClick() {
    //   this.uploadVisible = true
    // },
    closeDialog() {
      this.uploadVisible = false
    },
    uploadSuccess() {
      this.uploadVisible = false
      this.$emit('updateChapterList')
    },
    addArticleClick() {
      console.log(this.encryptionStr, 'encryptionStr')
      console.log(this.type, typeof (this.type), 'type')
      if (this.encryptionStr === '-1') {
        this.addContentSpaceNode(-1)
      } else {
        this.isLoading = true
        getForId({ encryptionStr: this.encryptionStr }).then(res => {
          if (res.code === '000000' && res.data) {
            console.log('当前节点的ID', res.data)
            this.addContentSpaceNode(res.data)
          } else {
            this.isLoading = false
          }
        }).catch(err => {
          this.isLoading = false
          console.log(err)
        })
      }
    },
    addContentSpaceNode(id) {
      const params = {
        parentId: id
      }
      addContentSpaceNew(params).then(response => {
        if (response.code === '000000' && response.data) {
          console.log('response', response.data)
          this.$emit('updateChapterList')
          const { href } = this.$router.resolve({ path: '/wiki/article/' + response.data })
          window.open(href, '_blank')
        }
        this.isLoading = false
      }).catch(error => {
        console.log(error)
        this.isLoading = false
        // 创建节点失败，给出提示
      })
    },
    addFolderClick() {
      this.visible = true
    },
    closeTab() {
      this.visible = false
    },
    addFolderSuccess() {
      this.visible = false
      this.$emit('updateChapterList')
    },
    addNewFileClick(file_type) {
      this.visible = true
      this.file_type = file_type
    }
  }
}
</script>
