<template>
  <div>
  <el-popover
    placement="right-start"
    width="180"
    trigger="hover">
    <div class="right-panel">
      <ul class="operate-list">
        <li class="operate-item" @click="shareClick">
          <span class="icon">
            <svg-icon icon-class="share"></svg-icon>
          </span>
          <span class="operate">分享</span>
        </li>
        <li class="operate-item" @click="fuzhi()">
          <span class="icon">
            <svg-icon icon-class="links"></svg-icon>
          </span>
          <span class="operate" v-clipboard:copy="encryptionStr">复制链接</span>
          <!-- 复制成功提示 -->
          <div class="message-tip" v-if="copy">
            <el-icon class="el-icon-check"></el-icon>
          </div>
        </li>
        <el-divider></el-divider>
        <li class="operate-item" @click="collectClick">
          <span class="icon">
            <svg-icon icon-class="collect"></svg-icon>
          </span>
          <span class="operate">{{ treeData.isCollect ? '取消收藏' : '添加到收藏'}}</span>
        </li>
        <el-divider></el-divider>
        <li class="operate-item" @click="deleteBatch">
          <span class="icon">
            <svg-icon icon-class="recycle"></svg-icon>
          </span>
          <span class="operate">移除</span>
        </li>
      </ul>
    </div>
    <span slot="reference" @click.stop="">
      <svg-icon icon-class="ellipsis"></svg-icon>
    </span>
  </el-popover>
    <!-- 分享 弹窗 -->
    <share-dialog
      :dialogVisible="shareVisible"
      :chapterObj="chapterObj"
      @closeShareDialog="closeShareDialog"
      @powerSuccess="powerSuccess">
    </share-dialog>
  </div>
</template>
<script>
import shareDialog from '@/components/shareDialog/index'
import { collectSpace } from '@/api/collect'
import { deleteLatelyList } from '@/api/template'

export default {
  components: {
    shareDialog
  },
  data() {
    return {
      copy: false,
      loading: false,
      shareVisible: false,
      chapterObj: {
        chapterId: null,
        encryptionStr: '',
        linkToAll: 1,
        linkWrite: 0
      }
    }
  },
  props: {
    treeData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    encryptionStr() {
      // console.log(this.treeData)
      let router = window.location.origin
      // dzz的文档
      if (this.treeData.dpath) {
        router += `/wiki/transfer/${this.treeData.encryptionStr}`
      } else {
        router += this.treeData.hasArticle === 1 ? `/wiki/article/${this.treeData.encryptionStr}` : `/drive/share/folder/${this.treeData.encryptionStr}`
      }
      // console.log('地址', router)
      return router
      // return window.location.origin + router + this.treeData.encryptionStr
      // return window.location.origin + '/wiki/article/' + this.treeData.encryptionStr
    }
  },
  methods: {
    fuzhi() {
      console.log(this.treeData)
      console.log(this.encryptionStr)
      this.copy = true
      setTimeout(() => {
        this.copy = false
      }, 1500)
    },
    collectClick() {
      this.$confirm('是否要收藏该节点或文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        collectSpace({
          chapterId: this.treeData.id,
          username: this.$store.getters.name
        }).then(response => {
          if (response.code === '000000') {
            this.$message.success(response.message)
            this.$emit('deleteSuccess')
            this.$parent.getList()
          } else {
            this.$message.error(response.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch((err) => {
        console.log(err)
        this.$message({
          type: 'info',
          message: '已取消收藏操作'
        })
      })
    },
    shareClick() {
      this.shareVisible = true
      this.chapterObj = Object.assign(this.chapterObj, this.treeData)
    },
    closeShareDialog() {
      this.shareVisible = false
    },
    powerSuccess() {
      this.$emit('addFolderSuccess')
    },
    deleteBatch() {
      this.$confirm('移除后，该内容在最近列表中将不可见。', '是否从最近列表中移除？', {
        confirmButtonText: '移除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const idList = [this.treeData.chapterId]
        deleteLatelyList({ chapterId: idList }).then(response => {
          if (response.code === '000000') {
            this.$message.success(response.message)
            this.$parent.getList()
          } else {
            this.$message.error(response.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    position: relative;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 40px;
      margin-right: 5px;
      .svg-icon {
        width: 1.2em;
        height: 1.2em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.el-divider--horizontal {
  margin: 5px 0;
}
.message-tip {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 99;
  .el-icon-check {
    color: #409eff;
  }
}
.disabled {
  color: #bbb;
  cursor: not-allowed !important;
}
</style>
