// 模板 增删改查
import request from '@/utils/request'

export function getTemplate() {
  return request({
    url: '/template/getTemplate',
    method: 'get'
  })
}

// 最近打开/编辑
export function getLatelyList(params) {
  return request({
    url: '/template/getLatelyList',
    method: 'get',
    params
  })
}

// 最近编辑文章--type不为mk
export function viewLatelyList(params) {
  return request({
    url: '/template/viewLatelyList',
    method: 'post',
    data: params
  })
}
// 移除最近打开/修改文件
export function deleteLatelyList(params) {
  return request({
    url: '/template/deleteLatelyList',
    method: 'post',
    data: params
  })
}
