<template>
  <div style="display:flex; flex-direction:column;flex:1;" :class="{'paddingBottom': checkedLen>0}">
    <div class="list-main" style="position:sticky;top:30px;background:#ffffff;z-index:9;">
      <span class="checkbox" @click="checkAllSelected" v-if="listData.length>0">
        <span class="checkbox-inner" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'':'somecheck')"></span>
      </span>
      <div class="flexJK">
        <span class="file-name select-panel">
          <el-select v-model="fileType" size="small" style="width:100px" :class="{'selected': fileType !== 'all'}" @change="change('type', fileType)">
            <el-option label="所有类型" value="all"></el-option>
            <el-option label="Markdown" value="markdown"></el-option>
            <el-option label="Word" value="newDoc"></el-option>
            <el-option label="Excel" value="newExcel"></el-option>
            <el-option label="PowerPoint" value="newPowerPoint"></el-option>
            <!-- <el-option label="知识库" value="2"></el-option> -->
          </el-select>
        </span>
        <span class="gd"></span>
        <span class="file-owner select-panel">
          <el-select v-model="isMy" size="small" style="width:90px" :class="{'selected': isMy !== 1}" @change="change('ownerCode', isMy)">
            <el-option label="不限归属" :value="1"></el-option>
            <el-option label="归我所有" :value="2"></el-option>
          </el-select>
        </span>
        <span class="gd"></span>
        <span class="file-updatetime select-panel">
          <el-select v-model="newly" size="small" style="width:90px" :class="{'selected': newly !== 0}" @change="change('isEdit', newly)">
            <el-option label="最近打开" :value="0"></el-option>
            <el-option label="最近修改" :value="1"></el-option>
          </el-select>
        </span>
        <span class="operate-btn">
          <svg-icon icon-class="list-view" v-if="nowType==='card'"></svg-icon>
          <svg-icon icon-class="card-view" v-if="nowType==='list'"></svg-icon>
        </span>
      </div>
    </div>
    <div v-if="listData.length === 0" style="text-align: center;
    margin-top: 20px;">暂无数据</div>
    <ul class="list-container" v-if="listData.length>0" v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-delay="500" infinite-scroll-distance="30">
      <li class="list-main" v-for="(item, index) in listData" :key="index">
        <span class="checkbox" @click="checkSelected(item, index)" v-if="listData.length>0">
          <span class="checkbox-inner" :class="item.checked?'allcheck':''"></span>
        </span>
        <!-- 目录则打开下一级，文章则到文章展示页 -->
        <a @click="forDetail(item)" style="display:flex;flex: 1 1 0%; flex-direction: row">
          <span class="file-name">
            <span class="icon">
              <template v-if="item.hasArticle === 1">
                <svg-icon v-if="!item.type" icon-class="markdown_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newExcel'" icon-class="excel_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newPowerPoint'" icon-class="ppt"></svg-icon>
                <svg-icon v-else-if="item.type==='newMp4'" icon-class="mp4"></svg-icon>
                <svg-icon v-else-if="item.type==='newDoc'" icon-class="word"></svg-icon>
                <svg-icon v-else icon-class="word"></svg-icon>
              </template>
              <svg-icon v-else icon-class="folder_icon"></svg-icon>
            </span>
            <span class="name">
            <i v-if="item.origin === 2" class="el-icon-s-management"></i>{{item.name}}</span>
            <span :class="item.isCollect ? 'collect-img-yes' : 'collect-img'" @click.stop="collectClick(item)">
              <el-tooltip effect="dark" :content="item.isCollect ? '取消收藏' : '收藏'" placement="top-start">
                <svg-icon v-if="item.isCollect" icon-class="collect_yellow"></svg-icon>
                <svg-icon v-else icon-class="collect_white"></svg-icon>
              </el-tooltip>
            </span>
          </span>
          <span class="gd"></span>
          <span class="file-owner">{{item.username}}</span>
          <span class="file-updatetime">{{item.time ? item.time : ''}}</span>
        </a>
        <span class="operate-btn">
          <right-operate :treeData="item"></right-operate>
        </span>
      </li>
      <li v-show="isLoading" style="text-align:center;line-height:30px;">数据加载中...</li>
    </ul>
    <div class="bottomFixed" v-show="checkedLen>0">
      <div class="list-main">
        <span class="checkbox" @click="checkAllSelected">
          <span class="checkbox-inner" style="margin-top:8px;" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'':'somecheck')"></span>
        </span>
        <el-button @click="deleteBatch" type="danger" size="small" plain style="height:32px" :disabled="checkedLen < 1">移除</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import rightOperate from './rightOperate'
import { newFile } from '@/api/fileApi'
import { collectSpace } from '@/api/collect'
// import { softDeleteSpace } from '@/api/space'
import { getLatelyList, deleteLatelyList } from '@/api/template'
export default {
  components: { rightOperate },
  data() {
    return {
      nowType: 'list',
      listData: [],
      checkedLen: 0,
      fileType: 'all',
      isMy: 1,
      newly: 0,
      searchForm: {
        type: 'all',
        isEdit: 0,
        ownerCode: ''
      }
    }
  },
  mounted() {
    this.searchForm = {
      type: 'all',
      isEdit: 0,
      ownerCode: ''
    }
    this.newly = 0
    this.isLoading = false
    this.noMore = false
    this.getList()
  },
  computed: {
    disabled() {
      return this.isLoading || this.noMore
    }
  },
  methods: {
    getList() {
      const params = {
        type: this.searchForm.type,
        isEdit: this.searchForm.isEdit,
        ownerCode: this.searchForm.ownerCode,
        username: this.$store.getters.name,
        workcode: this.$store.getters.workcode
      }
      this.isLoading = true
      getLatelyList(params).then(res => {
        this.isLoading = false
        this.listData = JSON.parse(JSON.stringify(res.data)) || []
      }).catch(() => { this.isLoading = false })
    },
    checkAllSelected() {
      const len = this.listData.filter(i => i.checked).length
      const allLen = this.listData.length
      if (len === allLen) {
        this.checkedLen = 0
        this.listData.forEach(i => { i.checked = false })
      } else {
        this.checkedLen = this.listData.length
        this.listData.forEach(i => { i.checked = true })
      }
    },
    checkSelected(item, index) {
      this.$nextTick(() => {
        // this.listData[index].checked = !this.listData[index].checked
        item.checked = !item.checked
        this.checkedLen = this.listData.filter(i => i.checked).length
        console.log(item)
      })
    },
    forDetail(item) {
      console.log(item)
      // 是否是知识库中的文件
      if (item.origin === 2) {
        const { href } = this.$router.resolve({ path: '/wiki/detail/' + item.encryptionStr })
        window.open(href, '_blank')
        return
      }
      // 是否是上传的文件
      if (item.type) {
        const Base64 = require('js-base64').Base64
        const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
        if (item.type === 'newMp4' || item.type === 'other') {
          newFile({
            action: 'getFileDetail',
            code: urlCode,
            path: item.dpath
          }).then((res) => {
            if (item.type === 'other') {
              window.open(res.data.url, '_blank')
            } else {
              // 在新路由页面打开视频播放
              const { href } = this.$router.resolve({
                path: `/wiki/player?name=${res.data.detail.name}&url=${res.data.url}`
              })
              window.open(href, '_blank')
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          const href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + item.dpath + `&code=${urlCode}`
          window.open(href, '_blank')
        }
        return
      }
      // Markdown文件
      const { href } = this.$router.resolve({ path: '/wiki/article/' + item.encryptionStr })
      window.open(href, '_blank')
    },
    deleteBatch() {
      this.$confirm('移除后，该内容在最近列表中将不可见。', '是否从最近列表中移除？', {
        confirmButtonText: '移除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const idList = this.listData.filter(item => item.checked).map(item => item.chapterId)
        deleteLatelyList({ chapterId: idList }).then(response => {
          if (response.code === '000000') {
            this.$message.success(response.message)
            this.checkedLen = 0
            this.getList()
          } else {
            this.$message.error(response.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {})
    },
    collectClick(item) {
      collectSpace({
        chapterId: item.id,
        username: this.$store.getters.name
      }).then(response => {
        if (response.code === '000000') {
          this.$message.success(response.message)
          item.isCollect = item.isCollect ? null : item.id
          this.getList()
          // this.getChapterList()
        } else {
          this.$message.error(response.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    change(val, data) {
      if (val === 'type') {
        this.searchForm.type = data
      } else if (val === 'ownerCode') {
        this.searchForm.ownerCode = data === 2 ? this.$store.getters.workcode : ''
      } else if (val === 'isEdit') {
        this.searchForm.isEdit = data
      }
      this.getList()
    },
    load() {
      this.isLoading = true
      console.log('加载数据')
    },
    getData() {

    }
  }
}
</script>
<style lang="scss" scoped>
.paddingBottom {
  padding-bottom: 30px;
}
.list-main {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d8dadb;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #f4f6f7;
    .collect-img {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }
  .collect-img {
    display: none;
  }
  .collect-img-yes {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
  .checkbox {
    width: 24px;
    display: flex;
    flex: 0 0 auto;
  }
  .gd {
    width: 10px;
  }
  .file-name {
    display: flex;
    flex-grow: 8;
    flex-shrink: 1;
    flex-basis: 100px;
    padding-left: 0;
    .icon {
      display: block;
      width: 30px;
      height: 30px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
        margin-top: 12px;
      }
    }
    .name {
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      max-width: 500px;
    }
  }
  .file-owner {
    display: flex;
    flex-grow: 6;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .file-updatetime {
    display: flex;
    flex-grow: 4;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .operate-btn {
    display: flex;
    flex: 0 0 auto;
    padding: 0 12px;
    .svg-icon {
      width: 1.2em;
      height: 1.2em;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}
.list-container {
  flex: auto;
  .list-main {
    height: 50px;
    line-height: 50px;
    a {
      color: #808183;
    }
    &:hover {
      a {
        color: #409eff;
      }
    }
  }
  .checkbox-inner {
    margin-top: 15px;
  }
  .operate-btn {
    cursor: pointer;
    span {
      padding-top: 5px;
    }
    .svg-icon {
      margin-top: 16px !important;
    }
  }
}
.bottomFixed {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: calc(100% - 310px);
  background: #ffffff;
  .list-main {
    height: 50px;
    border-top: 1px solid #d8dadb;
    border-bottom: 0 none;
    padding: 8px 0;
  }
}
.checkbox-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: 12px;
  background-color: #fff;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
  &:hover {
    border-color: #409eff;
    cursor: pointer;
  }
  &::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
  }
  // 全选
  &.allcheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      transform: rotate(45deg) scaleY(1);
    }
  }
  // 未全选
  &.somecheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: #fff;
      height: 2px;
      width: 12px;
      transform: scale(.5);
      left: 0;
      right: 0;
      top: 5px;
    }
  }
}
.flexJK {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  .select-panel {
    /deep/.el-input__inner {
      border: 0 none !important;
      padding: 0;
      font-size: 14px;
      color: #808183;
    }
    /deep/.selected {
      .el-input__inner, .el-select__caret {
        color: #409EFF;
      }
    }
    /deep/.el-input__suffix {
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>
