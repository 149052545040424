<template>
  <div class="app-container">
    <div class="top-panel">
      <div class="path">
        主页
      </div>
      <div class="right-btn">
        <!-- 主页 新增内容默认挂在我的空间下 -->
        <right-btn :type="type" :encryptionStr="encryptionStr" @updateChapterList="getChapterList"></right-btn>
      </div>
    </div>
    <div class="home-main-container">
      <div class="speed-create" v-loading="isLoading">
        <div class="name-head">模板构建</div>
        <el-row :gutter="20">
          <el-col :xs="8" :sm="8" :md="6" :lg="6" v-for="item in templates" :key="item.id">
            <el-card shadow="hover">
              <div class="card">
                <div class="card-icon">
                  <svg-icon icon-class="meeting"></svg-icon>
                </div>
                <div class="card-tip">
                  <div class="card-tip-top">{{item.title}}</div>
                  <div class="card-tip-bottom">{{item.comment}}</div>
                </div>
                <el-tooltip class="item" effect="dark" content="快速构建" placement="top">
                  <div class="card-icon card-icon-go" @click="create(item)">
                    <svg-icon icon-class="right"></svg-icon>
                  </div>
                </el-tooltip>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div class="file-list">
        <div class="name-head sticky">最近</div>
        <file-list></file-list>
      </div>
    </div>
  </div>
</template>
<script>
import rightBtn from '@/components/folderView/rightBtn'
import fileList from './components/fileList.vue'
import { getTemplate } from '@/api/template'
import { addContentSpaceNew } from '@/api/space'
export default {
  components: { rightBtn, fileList },
  data() {
    return {
      type: '',
      encryptionStr: '-1',
      templates: [],
      isLoading: false
    }
  },
  created() {
    this.getTemplateList()
  },
  methods: {
    getChapterList() {
      // 新增成功，默认刷新最近操作列表
    },
    getTemplateList() {
      this.templates = []
      this.isLoading = true
      getTemplate().then(res => {
        if (res.code === '000000') {
          this.templates = res.data
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => { this.isLoading = false })
    },
    create(item) {
      this.$confirm(`使用${item.title}模板创建文档？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          parentId: -1,
          title: item.title,
          content: item.content
        }
        addContentSpaceNew(params).then(response => {
          if (response.code === '000000' && response.data) {
            console.log('response', response.data)
            this.$emit('updateChapterList')
            const { href } = this.$router.resolve({ path: '/wiki/article/' + response.data })
            window.open(href, '_blank')
          }
          this.isLoading = false
        }).catch(error => {
          console.log(error)
          this.isLoading = false
        // 创建节点失败，给出提示
        })
      }).catch()
    }
  }
}
</script>
<style lang="scss" scope>
.top-panel {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  padding-bottom: 10px;
  height: 43px;
  .path {
    font-size: 18px;
    color: #38383a;
    display: flex;
    font-weight: bold;
  }
  .right-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
.home-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  .name-head {
    font-size: 15px;
    font-weight: bold;
    height: 30px;
    padding-bottom: 13px;
    background: #ffffff;
    color: #38383a !important;
  }
  .speed-create {
    width: 100%;
    height: 135px;
    .el-row {
      height: 105px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      .el-col:first-child {
        padding-left: 0 !important;
      }
    }
    .card {
      height: 50px;
      display: flex;
      flex-direction: row;
      .card-icon {
        width: 50px;
        .svg-icon {
          width: 2em;
          height: 2em;
          margin-top: 8px;
        }
      }
      .card-icon-go {
        text-align: center;
        cursor: pointer;
        .svg-icon {
          width: 1.4em;
          height: 1.4em;
          margin-top: 12px;
        }
        &:hover {
          .svg-icon {
            color: #3370ff;
          }
        }
      }
      .card-tip {
        display: flex;
        flex: 1;
        flex-direction: column;
        .card-tip-top {
          height: 30px;
          line-height: 30px;
          display: flex;
          font-size: 14px;
          font-weight: bold;
        }
        .card-tip-bottom {
          height: 20px;
          display: flex;
          line-height: 20px;
          font-size: 12px;
        }
      }
    }
  }
  .file-list {
    font-size: 14px;
    color: #808183;
    flex: 1;
    width: 100%;
    padding-right: 10px;
    position: relative;
    .sticky {
      position: sticky;
      z-index: 2;
      top: 0;
    }
    display: flex;
    flex-direction: column;
  }
}
</style>
